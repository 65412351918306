import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const routeSwap = trigger('routeSwap', [
  transition('void => *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('300ms ease-out', style({ opacity: '*', transform: '*' })),
      ],
      { optional: true }
    ),
  ]),
  transition(':decrement', [
    style({
      position: 'relative',
      overflow: 'hidden',
    }),
    group([
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }),
      ]),
      query(':enter', [style({ top: '-128px', opacity: 0 })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ top: '128px', opacity: 0 })),
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ top: 0, opacity: '*' })),
        ]),
      ]),
      query(':enter', animateChild()),
    ]),
  ]),
  transition(':increment', [
    style({
      position: 'relative',
      overflow: 'hidden',
    }),
    group([
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }),
      ]),
      query(':enter', [style({ top: '128px', opacity: 0 })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ top: '-128px', opacity: 0 })),
        ]),
        query(':enter', [
          animate('300ms ease-out', style({ top: 0, opacity: '*' })),
        ]),
      ]),
      query(':enter', animateChild()),
    ]),
  ]),
]);

export const expand = trigger('expand', [
  state(
    'collapsed',
    style({ height: '0px', minHeight: '0', opacity: '0', overflow: 'hidden' })
  ),
  state('expanded', style({ height: '*', opacity: '*' })),
  transition(
    'expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
  ),
]);

export const fadeIn = trigger('fadeIn', [
  state(
    'void',
    style({
      opacity: 0,
      transform: 'scale(0.8)',
    })
  ),
  transition(
    ':enter',
    animate(
      '120ms cubic-bezier(0, 0, 0.2, 1)',
      style({ opacity: '*', transform: 'scale(1)' })
    )
  ),
  transition(':leave', animate('100ms 25ms linear', style({ opacity: 0 }))),
]);

export const slideIn = trigger('slideIn', [
  transition(':enter', [
    style({ 'margin-right': '-400px' }),
    animate('.225s ease', style({ 'margin-right': 0 })),
  ]),
  transition(':leave', [
    style({ 'margin-right': 0 }),
    animate('.225s ease', style({ 'margin-right': '-400px' })),
  ]),
]);
